import styled from 'styled-components'
import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Text } from '../components/typography'
import { Container, Hero, Text as TextBlock, TextContainer } from '../utils'
import { Card, Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import maron from '../images/team/maron.png'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const ProfilePicture = styled.img`
    width: 260px;
    height: 260px;
    margin: 0 16px 16px 0;
`

const Pair = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`

const Item = styled.div`
    width: 100%;

    @media screen and (min-width: 768px) {
        width: ${(props) => (props.width ? props.width : 100)}%;
        order: ${(props) => (props.left ? 0 : 1)};
    }
`

const AboutUsHero = styled(Hero)`
    background-color: ${(props) => props.theme.color.tellowPurple};
`

const CardHeader = styled.div`
    font-weight: bold;
    line-height: 30px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        margin: 10px 0;
    }
`
const AboutUsPage = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'boekhouden', 'tellow', 'over ons']}
            title="Over ons - Tellow"
            description="Wij zijn Tellow en dit is onze missie."
            path="/over-ons"
            jsonLD={reviewStructuredData}
        />
        <AboutUsHero background-color={null}>
            <Container>
                <Pair>
                    <Item>
                        <TextBlock>
                            <Heading color="tellowWhite" size={40} sizeTablet={60} sizeDesktop={70} style={{ padding: '100px 0' }}>
                                Wij zijn Tellow en dit is onze missie{' '}
                            </Heading>
                        </TextBlock>
                    </Item>
                    <Item>
                        <TextBlock>
                            <Card pullLeft>
                                <Text color="tellowPurple" semibold>
                                    Wat?
                                </Text>
                                <CardHeader>We willen zzp'ers de vrijheid geven om te ondernemen.</CardHeader>
                                <div>Omdat zelfstandig ondernemen een feestje is!</div>
                            </Card>
                            <Card>
                                <Text color="tellowPurple" semibold>
                                    Hoe?
                                </Text>
                                <CardHeader>We maken gebruiksvriendelijke en tijdbesparende oplossingen.</CardHeader>
                                <div>Zodat jij kan ondernemen met een glimlach.</div>
                            </Card>
                            <Card pullLeft>
                                <Text color="tellowPurple" semibold>
                                    Waarom?
                                </Text>
                                <CardHeader>We beginnen met het automatiseren van de boekhouding.</CardHeader>
                                <div>Het plezier van een goede administratie.</div>
                            </Card>
                        </TextBlock>
                    </Item>
                </Pair>
            </Container>
        </AboutUsHero>
        <div>
            <TextContainer>
                <Pair>
                    <Item width={60}>
                        <p>
                            Op dit moment richten we ons bij Tellow volledig op ons boekhoudprogramma. Doel is om ervoor te zorgen dat jij geen enkele zorg hebt over je
                            administratie, zodat je als zelfstandige kan doen wat je het liefste doet met je werk én met je leven.
                        </p>
                        <Heading size={25} sizeTablet={35} sizeDesktop={45}>
                            "Lang leve de zzp'er!"
                        </Heading>
                    </Item>
                    <Item style={{ textAlign: 'center', minWidth: 300 }} width={40}>
                        <ProfilePicture src={maron} alt="Maron: Tellow medewerker" />
                    </Item>
                </Pair>
            </TextContainer>
        </div>
        {/* <FooterCTABlock /> */}
    </Layout>
)

export default AboutUsPage

export const query = graphql`
    {
        allFile(filter: { relativePath: { regex: "pages/team/" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`
